import {UserModel} from '../models/UserModel'

export const ForteClientCode = 'Forte'
export enum ForteAssessmentTypes {
  OrganizationalCultureAssessment = 'Organizational Culture Assessment',
  InterviewQuestions = 'Interview Questions',
  CultureIQQuestions = 'Culture IQ Questions',
}

export enum ForteCultureAssessmentTypes {
  DominantAttributes = 'Dominant Attributes',
  LeadershipStyle = 'Leadership Style',
  ManagementStyle = 'Management Style',
  OrganizationalUnity = 'Organizational Unity',
  StrategicSignificance = 'Strategic Significance',
  SuccessFactors = 'Success Factors',
}

export const allCultureAssessmentTypes: ForteCultureAssessmentTypes[] = [
  ForteCultureAssessmentTypes.DominantAttributes,
  ForteCultureAssessmentTypes.LeadershipStyle,
  ForteCultureAssessmentTypes.ManagementStyle,
  ForteCultureAssessmentTypes.OrganizationalUnity,
  ForteCultureAssessmentTypes.StrategicSignificance,
  ForteCultureAssessmentTypes.SuccessFactors,
]
export type ForteOrgCultureAssessmentQuestionT = {
  _id: string
  cultureType?: ForteCultureAssessmentTypes
  order: number
  opinionA: string
  opinionB: string
  opinionC: string
  opinionD: string
}
type AnswerValue = {
  now: number
  preferred: number
  notes: string
}

export type ForteOrgCultureAssessmentAnswerT = {
  _id?: string
  questionId?: string
  question?: ForteOrgCultureAssessmentQuestionT
  userId: string
  user?: UserModel
  opinionA: AnswerValue
  opinionB: AnswerValue
  opinionC: AnswerValue
  opinionD: AnswerValue
}
export type ForteInterviewQuestionT = {
  _id: string
  question: string
  order: number
}
export type ForteInterviewQuestionAnswerT = {
  _id?: string
  questionId?: string
  question?: ForteInterviewQuestionT
  userId: string
  user?: UserModel
  answer: string
}
export type ForteCultureIQQuestionT = {
  _id: string
  question: string
  order: number
}

export type ForteCultureIQQuestionAnswerT = {
  _id?: string
  questionId?: string
  question?: ForteCultureIQQuestionT
  userId: string
  user?: UserModel
  answer: string
}
