import React from 'react'
import {AsideMenuItem} from '../AsideMenuItem'
import routerItems from '../../../../../app/routing/routerItems'
import {useIntl} from 'react-intl'
import i18nKeys from '../../../../i18n/i18nKeys'
import useUser from '../../../../../app/hooks/useUser'
import {ForteClientCode} from '../../../../../app/constants/forteTypes'

export default function ClientManagerAsideMenu() {
  const {formatMessage} = useIntl()
  const {isSuperAdmin, user} = useUser()
  const isAccessibleToForte = isSuperAdmin || user?.client?.code === ForteClientCode
  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-5 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {formatMessage({id: i18nKeys.CLIENT_MANAGERS_PAGES})}
          </span>
        </div>
      </div>

      <AsideMenuItem
        to={routerItems.MY_CLIENT_INFO}
        title={formatMessage({id: i18nKeys.MY_COMPANY})}
        icon='/media/icons/duotune/general/gen001.svg'
      />
      {isAccessibleToForte && (
        <AsideMenuItem
          to={routerItems.MY_CLIENT_FORTE_ASSESSMENT_MANAGEMENT}
          title={'Forte Assessment Management'}
          icon='/media/icons/duotune/general/gen001.svg'
        />
      )}
      <AsideMenuItem
        icon='/media/icons/duotune/maps/map001.svg'
        to={routerItems.MY_CLIENT_ASSESSMENT_FORMS}
        title={formatMessage({id: i18nKeys.ASSIGN_ASSESSMENT})}
      />
      <AsideMenuItem
        icon='/media/icons/duotune/coding/cod006.svg'
        to={routerItems.MY_CLIENT_ASSIGN_REPORTS}
        title={'Assigned Internal Reports'}
      />
      <AsideMenuItem
        icon='/media/icons/duotune/coding/cod003.svg'
        to={routerItems.MY_CLIENT_ASSIGN_CUSTOM_REPORTS}
        title={'Assign Custom Reports'}
      />
      <AsideMenuItem
        icon='/media/icons/duotune/general/gen015.svg'
        to={routerItems.MY_CLIENT_TEAM_MANAGEMENT}
        title={formatMessage({id: i18nKeys.TEAM_MANAGEMENT})}
      />
      <AsideMenuItem
        to={routerItems.MY_CLIENT_SITES_JOBS}
        title={formatMessage({id: i18nKeys.COMPANY_SITES_JOBS})}
        icon='/media/icons/duotune/general/gen018.svg'
      />
      <AsideMenuItem
        to={routerItems.MY_CLIENT_NOTIFICATIONS}
        title={formatMessage({id: i18nKeys.COMPANY_NOTIFICATIONS})}
        icon='/media/icons/duotune/general/gen007.svg'
      />
      <AsideMenuItem
        to={routerItems.MY_CLIENT_NEWS}
        title={formatMessage({id: i18nKeys.COMPANY_NEWS})}
        icon='/media/icons/duotune/general/gen003.svg'
      />
      <AsideMenuItem
        to={routerItems.MY_CLIENT_TRENDS}
        title={formatMessage({id: i18nKeys.COMPANY_TRENDS})}
        icon='/media/icons/duotune/general/gen032.svg'
      />
      {/* <AsideMenuItem
        to={routerItems.MY_CLIENT_REPORTS}
        title={'Company Reports'}
        icon='/media/icons/duotune/general/gen032.svg'
      /> */}
    </>
  )
}
