import {Permission} from '../constants/userRoles'
import {
  AboutPage,
  AccountPage,
  ApplicationsPage,
  AssessmentPage,
  AssignAssessmentsPage,
  AssignAssessmentsToClientPage,
  AuditLogsPage,
  BaseDataPage,
  ClientsPage,
  ContactUsPage,
  DashboardAdminWrapper,
  DashboardApplicantWrapper,
  DashboardEmployeeWrapper,
  DashboardHrManagerWrapper,
  DashboardManagerWrapper,
  DashboardTeamLeaderWrapper,
  EEAssessmentResultsPage,
  EmailConfirmation,
  EmployeeDetailPage,
  EmployeePage,
  FAQManagementPage,
  FAQPage,
  ForgotPassword,
  ForteTakingAssessmentPage,
  HRAdminPage,
  InviteApplicant,
  JobsPage,
  Login,
  ModulesPage,
  MyAssessmentResultsDetailPage,
  MyAssessmentResultsPage,
  MyAssessmentsPage,
  MyClientsPage,
  MyReportPage,
  MySchedulesPage,
  MyTodoListPage,
  PrivacyPage,
  ProfilePage,
  PublicReportPage,
  QuestionPage,
  Registration,
  ReportManagementPage,
  ResetPassword,
  SuperAdminPages,
  TakingAssessmentPage,
  TeamLeaderPage,
  TermsPage,
  UsersPage,
} from './pages'
import routerItems from './routerItems'
export type RouteData = {
  path: string
  Component: React.LazyExoticComponent<(props: any) => JSX.Element>
  permission?: Permission
  hasChild?: boolean
}

const routes: RouteData[] = [
  {
    path: routerItems.CONTACT_US,
    Component: ContactUsPage,
    permission: Permission.Public,
  },
  {path: routerItems.PUBLIC_REPORT(), Component: PublicReportPage, permission: Permission.Public},
  {path: routerItems.FAQ, Component: FAQPage, permission: Permission.Public},
  {path: routerItems.TERMS, Component: TermsPage, permission: Permission.Public},
  {path: routerItems.PRIVACY, Component: PrivacyPage, permission: Permission.Public},
  {path: routerItems.ABOUT, Component: AboutPage, permission: Permission.Public},
  {path: routerItems.AUTH_LOGIN, Component: Login, permission: Permission.Public},
  {path: routerItems.AUTH_REGISTER, Component: Registration, permission: Permission.Public},
  {
    path: routerItems.AUTH_INVITE_APPLICANT,
    Component: InviteApplicant,
    permission: Permission.Public,
  },
  {
    path: routerItems.AUTH_EMAIL_CONFIRMATION,
    Component: EmailConfirmation,
    permission: Permission.Public,
  },
  {
    path: routerItems.AUTH_FORGOT_PASSWORD,
    Component: ForgotPassword,
    permission: Permission.Public,
  },
  {path: routerItems.RESET_PASSWORD, Component: ResetPassword, permission: Permission.Public},
  {
    path: routerItems.DASHBOARD,
    Component: DashboardApplicantWrapper,
    permission: Permission.Applicant,
  },
  {
    path: routerItems.DASHBOARD_EMPLOYEE,
    Component: DashboardEmployeeWrapper,
    permission: Permission.Employee,
  },
  {
    path: routerItems.DASHBOARD_TEAM_LEADER,
    Component: DashboardTeamLeaderWrapper,
    permission: Permission.TeamLeader,
  },
  {
    path: routerItems.DASHBOARD_HR_MANAGER,
    Component: DashboardHrManagerWrapper,
    permission: Permission.HrAdmin,
  },
  {
    path: routerItems.DASHBOARD_MANAGER,
    Component: DashboardManagerWrapper,
    permission: Permission.Manager,
  },
  {
    path: routerItems.DASHBOARD_ADMIN,
    Component: DashboardAdminWrapper,
    permission: Permission.SuperAdmin,
  },
  {
    path: routerItems.ACCOUNT,
    Component: AccountPage,
    permission: Permission.Authorized,
    hasChild: true,
  },
  {path: routerItems.PROFILE, Component: ProfilePage, permission: Permission.Authorized},
  {path: routerItems.MY_TODO_LIST, Component: MyTodoListPage, permission: Permission.Authorized},
  {
    path: routerItems.MY_ASSESSMENTS,
    Component: MyAssessmentsPage,
    permission: Permission.Authorized,
  },
  {
    path: routerItems.MY_ASSESSMENTS_TAKING_IN_PROGRESS,
    Component: TakingAssessmentPage,
    permission: Permission.Authorized,
  },
  {
    path: routerItems.FORTE_TAKING_ASSESSMENTS,
    Component: ForteTakingAssessmentPage,
    permission: Permission.Authorized,
  },
  {
    path: routerItems.MY_ASSESSMENT_RESULTS,
    Component: MyAssessmentResultsPage,
    permission: Permission.Authorized,
  },
  {
    path: routerItems.MY_ASSESSMENT_RESULTS_DETAIL,
    Component: MyAssessmentResultsDetailPage,
    permission: Permission.Authorized,
  },
  {path: routerItems.MY_SCHEDULES, Component: MySchedulesPage, permission: Permission.Authorized},
  {path: routerItems.MY_REPORTS, Component: MyReportPage, permission: Permission.Authorized},
  {
    path: routerItems.ASSIGN_ASSESSMENTS,
    Component: AssignAssessmentsPage,
    permission: Permission.Authorized,
  },
  {
    path: routerItems.ASSIGN_ASSESSMENTS_CLIENT,
    Component: AssignAssessmentsToClientPage,
    permission: Permission.Authorized,
  },
  {
    path: routerItems.SUPER_ADMIN,
    Component: SuperAdminPages,
    permission: Permission.SuperAdmin,
    hasChild: true,
  },

  {
    path: routerItems.HR_ADMIN_PAGE,
    Component: HRAdminPage,
    permission: Permission.HrAdmin,
    hasChild: true,
  },

  {
    path: routerItems.TEAM_LEADER_MEMBERS,
    Component: TeamLeaderPage,
    permission: Permission.TeamLeader,
  },
  {
    path: routerItems.EMPLOYEE_INFO_DETAIL,
    Component: EmployeeDetailPage,
    permission: Permission.Authorized,
    hasChild: true,
  },
  {
    path: routerItems.EMPLOYEE,
    Component: EmployeePage,
    permission: Permission.Authorized,
    hasChild: true,
  },
  {
    path: routerItems.MY_CLIENT,
    Component: MyClientsPage,
    permission: Permission.Manager,
    hasChild: true,
  },
  {
    path: routerItems.CLIENTS,
    Component: ClientsPage,
    permission: Permission.Authorized,
    hasChild: true,
  },
  {
    path: routerItems.SUPER_ADMIN_EMPLOYEE_ASSESSMENT_RESULTS,
    Component: EEAssessmentResultsPage,
    permission: Permission.Authorized,
    hasChild: true,
  },
  {
    path: routerItems.AUDIT_LOGS,
    Component: AuditLogsPage,
    permission: Permission.SuperAdmin,
    hasChild: true,
  },
  {path: routerItems.JOBS, Component: JobsPage, permission: Permission.SuperAdmin, hasChild: true},
  {
    path: routerItems.ASSESSMENTS,
    Component: AssessmentPage,
    permission: Permission.SuperAdmin,
    hasChild: true,
  },
  {
    path: routerItems.QUESTIONS,
    Component: QuestionPage,
    permission: Permission.SuperAdmin,
    hasChild: true,
  },
  {
    path: routerItems.REPORTS,
    Component: ReportManagementPage,
    permission: Permission.SuperAdmin,
    hasChild: true,
  },
  {
    path: routerItems.MODULES,
    Component: ModulesPage,
    permission: Permission.SuperAdmin,
    hasChild: true,
  },
  {
    path: routerItems.APPLICATIONS,
    Component: ApplicationsPage,
    permission: Permission.SuperAdmin,
    hasChild: true,
  },
  {
    path: routerItems.USERS,
    Component: UsersPage,
    permission: Permission.SuperAdmin,
    hasChild: true,
  },
  {
    path: routerItems.FAQ_MANAGEMENT,
    Component: FAQManagementPage,
    permission: Permission.SuperAdmin,
    hasChild: true,
  },
  {
    path: routerItems.BASE_DATA,
    Component: BaseDataPage,
    permission: Permission.SuperAdmin,
    hasChild: true,
  },
]

export default routes
