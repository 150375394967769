import {lazy} from 'react'
export const TermsPage = lazy(() => import('../modules/public-pages/TermsPage'))
export const PrivacyPage = lazy(() => import('../modules/public-pages/PrivacyPage'))
export const AboutPage = lazy(() => import('../modules/public-pages/AboutPage'))

export const DashboardApplicantWrapper = lazy(
  () => import('../modules/dashboard/DashboardApplicantWrapper')
)
export const DashboardEmployeeWrapper = lazy(
  () => import('../modules/dashboard/DashboardEmployeeWrapper')
)
export const DashboardHrManagerWrapper = lazy(
  () => import('../modules/dashboard/DashboardHrManagerWrapper')
)
export const DashboardManagerWrapper = lazy(
  () => import('../modules/dashboard/DashboardManagerWrapper')
)
export const DashboardAdminWrapper = lazy(
  () => import('../modules/dashboard/DashboardAdminWrapper')
)

export const MyAssessmentsPage = lazy(
  () => import('../modules/employee-modules/my-assessments/MyAssessmentsPage')
)
export const TakingAssessmentPage = lazy(
  () => import('../modules/employee-modules/my-assessments/components/TakingAssessment')
)
export const ForteTakingAssessmentPage = lazy(
  () => import('../modules/employee-modules/my-assessments/forte/ForteTakingAssessment')
)

export const ReportManagementPage = lazy(
  () => import('../modules/super-admin-modules/reports/ReportManagementPage')
)
export const PrivateRoutes = lazy(() => import('./PrivateRoutes'))
export const ForgotPassword = lazy(() => import('../modules/auth/components/ForgotPassword'))
export const EmailConfirmation = lazy(() => import('../modules/auth/components/EmailConfirmation'))
export const Registration = lazy(() => import('../modules/auth/components/Registration'))
export const InviteApplicant = lazy(() => import('../modules/auth/components/InviteApplicant'))
export const Login = lazy(() => import('../modules/auth/components/Login'))
export const ContactUsPage = lazy(() => import('../modules/public-pages/ContactUsPage'))
export const FAQPage = lazy(() => import('../modules/public-pages/FAQPage'))
export const PublicReportPage = lazy(() => import('../modules/public-pages/PublicReport'))
export const FAQManagementPage = lazy(
  () => import('../modules/super-admin-modules/faqs/FAQManagementPage')
)
export const EEAssessmentResultsPage = lazy(
  () =>
    import(
      '../modules/super-admin-modules/employee-assessment-results/EmployeeAssessmentResultPage'
    )
)
export const ResetPassword = lazy(() => import('../modules/auth/components/ResetPassword'))
export const DashboardTeamLeaderWrapper = lazy(
  () => import('../modules/dashboard/DashboardTeamLeaderWrapper')
)
export const EmployeePage = lazy(() => import('../modules/employee-pages/EmployeePage'))
export const MySchedulesPage = lazy(
  () => import('../modules/employee-modules/my-schedules/MySchedulesPage')
)
export const AuditLogsPage = lazy(
  () => import('../modules/super-admin-modules/audit-logs/AuditLogsPage')
)

export const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
export const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))

export const EmployeeDetailPage = lazy(
  () => import('../modules/manager-modules/EmployeeDetailPage')
)
export const AssignAssessmentsPage = lazy(
  () => import('../modules/super-admin-modules/assign-assessments/AssignAssessmentsPage')
)

export const MyAssessmentResultsPage = lazy(
  () => import('../modules/employee-modules/my-assessment-results/MyAssessmentResultsPage')
)
export const MyAssessmentResultsDetailPage = lazy(
  () => import('../modules/employee-modules/my-assessment-results/MyAssessmentResultsDetailPage')
)
export const MyTodoListPage = lazy(
  () => import('../modules/employee-modules/my-todo-list/MyTodoListPage')
)
export const MyReportPage = lazy(() => import('../modules/employee-modules/my-report/MyReportPage'))
export const AssignAssessmentsToClientPage = lazy(
  () => import('../modules/super-admin-modules/assign-assessments/AssignAssessmentsToClientPage')
)

export const HRAdminPage = lazy(() => import('../modules/hr-admin-modules/HRAdminPage'))
export const CompanyUsersForAssessmentResultsPage = lazy(
  () => import('../modules/hr-admin-modules/components/CompanyUsersForAssessmentResults')
)

export const TeamLeaderPage = lazy(() => import('../modules/team-leader-modules/TeamLeaderPage'))
export const MyClientsPage = lazy(() => import('../modules/manager-modules/MyClientPage'))

export const JobsPage = lazy(() => import('../modules/super-admin-modules/jobs/JobsPage'))
export const ClientsPage = lazy(() => import('../modules/super-admin-modules/clients/ClientsPage'))
export const ModulesPage = lazy(() => import('../modules/super-admin-modules/modules/ModulesPage'))
export const ApplicationsPage = lazy(
  () => import('../modules/super-admin-modules/applications/ApplicationsPage')
)
export const AssessmentPage = lazy(
  () => import('../modules/super-admin-modules/assessments/AssessmentPage')
)
export const QuestionPage = lazy(
  () => import('../modules/super-admin-modules/question/QuestionPage')
)

export const SuperAdminPages = lazy(() => import('../modules/super-admin-modules/SuperAdminPages'))

export const UsersPage = lazy(() => import('../modules/super-admin-modules/users/UserListPage'))
export const BaseDataPage = lazy(
  () => import('../modules/super-admin-modules/base-data/BaseDataPage')
)
export const ErrorsPage = lazy(() => import('../modules/errors/ErrorsPage'))
