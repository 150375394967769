const HOME = '/'

const CONTACT_US = '/contact-us'
const FAQ = '/faq'
const ABOUT = '/about'
const TERMS = '/terms'
const PRIVACY = '/privacy'

const AUTH = '/auth/'
const AUTH_LOGIN = AUTH + 'login'
const AUTH_LOGOUT = AUTH + 'logout'
const AUTH_REGISTER = AUTH + 'register'
const AUTH_INVITE_APPLICANT = AUTH + 'invite-applicant'
const AUTH_EMAIL_CONFIRMATION = AUTH + 'email-confirmation/:token'
const RESET_PASSWORD = AUTH + 'reset-password/:token'
const AUTH_FORGOT_PASSWORD = AUTH + 'forgot-password'
const AUTH_TERMS = AUTH + 'terms'

const POINT_LEADER_REPORTS = '/point-leader-reports'

const DASHBOARD = '/dashboard'
const DASHBOARD_EMPLOYEE = '/employee-dashboard'
const DASHBOARD_TEAM_LEADER = '/team-leader-dashboard'
const DASHBOARD_HR_MANAGER = '/hr-manager-dashboard'
const DASHBOARD_MANAGER = '/manager-dashboard'
const DASHBOARD_ADMIN = '/admin-dashboard'
const BUILDER = '/builder'

const EMPLOYEE = '/employee/'
const EMPLOYEE_PROFILE = EMPLOYEE + 'profile/'
const EMPLOYEE_PROFILE_PAGE = EMPLOYEE_PROFILE + ':employeeId'
const EMPLOYEE_GOAL_SETTING = EMPLOYEE + 'goal-settings/'
const EMPLOYEE_GOAL_SETTING_PAGE = EMPLOYEE_GOAL_SETTING + ':employeeId'

const PROFILE = '/profile'
const PROFILE_OVERVIEW = PROFILE + '/overview'
const PROFILE_PROJECTS = PROFILE + '/projects'
const PROFILE_CAMPAIGNS = PROFILE + '/campaigns'
const PROFILE_DOCUMENTS = PROFILE + '/documents'
const PROFILE_CONNECTIONS = PROFILE + '/connections'

const WIZARDS = '/wizards'
const WIDGETS = '/widgets'

const ACCOUNT = '/account'
const ACCOUNT_OVERVIEW = ACCOUNT + '/overview'
const ACCOUNT_SETTINGS = ACCOUNT + '/settings'
const ACCOUNT_NOTES = ACCOUNT + '/notes'

const JOBS = '/jobs/'
const JOBS_COMPETENCY_CUTS = JOBS + 'competency-cuts/'
const JOBS_COMPETENCY_CUTS_DETAIL = JOBS_COMPETENCY_CUTS + ':jobId'

const USERS = '/users'
const USERS_MANAGEMENT = USERS + '/management'
const USERS_NEW = USERS + '/new'
const USERS_DETAIL = USERS_MANAGEMENT + '/:id'
const USERS_DETAIL_OVERVIEW = USERS_DETAIL + '/overview'
const USERS_DETAIL_SETTINGS = USERS_DETAIL + '/settings'

const MY_CLIENT = '/my-client/'
const MY_CLIENT_INFO = MY_CLIENT + 'info/'
const MY_CLIENT_FORTE_ASSESSMENT_MANAGEMENT = MY_CLIENT + 'forte-assessment-management/'

const MY_CLIENT_ASSESSMENT_FORMS = MY_CLIENT + 'assessment-forms/'
const MY_CLIENT_ASSESSMENT_ASSIGN = MY_CLIENT_ASSESSMENT_FORMS + 'assign/'
const MY_CLIENT_ASSESSMENT_ASSIGN_DETAILS = MY_CLIENT_ASSESSMENT_ASSIGN + ':assessmentFormId'
const MY_CLIENT_TEAM_MANAGEMENT = MY_CLIENT + 'teams/'
const MY_CLIENT_ASSIGN_REPORTS = MY_CLIENT + 'company-reports/'
const MY_CLIENT_REPORTS_PREVIEW = (reportType = ':reportType') =>
  MY_CLIENT_ASSIGN_REPORTS + reportType
const MY_CLIENT_ASSIGN_CUSTOM_REPORTS = MY_CLIENT + 'company-custom-reports/'
const MY_CLIENT_CUSTOM_REPORTS_PREVIEW = (customReportId = ':customReportId') =>
  MY_CLIENT_ASSIGN_CUSTOM_REPORTS + 'preview/' + customReportId
const MY_CLIENT_CUSTOM_REPORTS_ASSIGN = (customReportId = ':customReportId') =>
  MY_CLIENT_ASSIGN_CUSTOM_REPORTS + 'assign/' + customReportId

const MY_CLIENT_SITES_JOBS = MY_CLIENT + 'sites-jobs/'
const MY_CLIENT_JOB_CUTS = MY_CLIENT + 'job-cuts/'
const MY_CLIENT_JOB_CUTS_DETAIL = MY_CLIENT_JOB_CUTS + ':clientSiteId/:jobId'
const MY_CLIENT_EMPLOYEES = MY_CLIENT + 'employees/'
const MY_CLIENT_TRENDS = MY_CLIENT + 'trends/'
const MY_CLIENT_NOTIFICATIONS = MY_CLIENT + 'notifications/'
const MY_CLIENT_NEWS = MY_CLIENT + 'news/'
const MY_CLIENT_REPORTS = MY_CLIENT + 'reports/'
const MY_CLIENT_REPORTS_EDIT = MY_CLIENT_REPORTS + ':reportId'

const EMPLOYEE_INFO = '/employee-info/'
const EMPLOYEE_INFO_DETAIL = EMPLOYEE_INFO + ':id/'
const EMPLOYEE_INFO_DETAIL_OVERVIEW = EMPLOYEE_INFO_DETAIL + 'overview'
const EMPLOYEE_INFO_DETAIL_SETTINGS = EMPLOYEE_INFO_DETAIL + 'settings'
const EMPLOYEE_INFO_DETAIL_NOTES = EMPLOYEE_INFO_DETAIL + 'notes'

const CLIENTS = '/clients/'
const CLIENTS_MANAGEMENT = CLIENTS + 'management/'
const CLIENTS_NEW = CLIENTS + 'new/'
const CLIENTS_DETAIL = CLIENTS_MANAGEMENT + ':id/'
const CLIENTS_SITES_JOBS = CLIENTS_DETAIL + 'jobs'
const CLIENTS_SITES_JOB_COMPETENCY_CUTS = CLIENTS + 'site-job-competency/'
const CLIENTS_SITES_JOB_COMPETENCY_CUTS_DETAIL =
  CLIENTS_SITES_JOB_COMPETENCY_CUTS + ':clientSiteId/:jobId'

const ASSIGN_ASSESSMENTS = '/assign-assessments/'
const ASSIGN_ASSESSMENTS_MANAGEMENT = ASSIGN_ASSESSMENTS + ':employeeId'
const ASSIGN_ASSESSMENTS_CLIENT = '/assign-assessments-client/'
const ASSIGN_ASSESSMENTS_CLIENT_MANAGEMENT = ASSIGN_ASSESSMENTS_CLIENT + ':clientId'

const TEAM_LEADER_PAGE = '/team-leader/'
const TEAM_LEADER_MEMBERS = TEAM_LEADER_PAGE + 'team-members/'
const TEAM_LEADER_MEMBER_REPORT = TEAM_LEADER_PAGE + 'team-member-report/'
const TEAM_LEADER_MEMBER_REPORT_PAGE = TEAM_LEADER_MEMBER_REPORT + ':employeeId'
const TEAM_LEADER_MEMBER_SUMMARY = TEAM_LEADER_PAGE + 'team-member-summary/'
const TEAM_LEADER_MEMBER_SUMMARY_PAGE = TEAM_LEADER_MEMBER_SUMMARY + ':employeeId'

const HR_ADMIN_PAGE = '/hr-admin/'

const HR_ADMIN_CLIENT_SITES_JOBS = HR_ADMIN_PAGE + 'sites-jobs/'
const HR_ADMIN_JOB_CUTS = HR_ADMIN_CLIENT_SITES_JOBS + 'job-cuts/'
const HR_ADMIN_JOB_CUTS_DETAIL = HR_ADMIN_JOB_CUTS + ':clientSiteId/:jobId'
const HR_ADMIN_COMPARE_CANDIDATES = HR_ADMIN_PAGE + 'compare-candidates/'
const HR_ADMIN_COMPARE_GROUP = HR_ADMIN_PAGE + 'compare-group/'
const HR_ADMIN_TEAMS_ASSIGN_TEAM_MEMBERS = HR_ADMIN_PAGE + 'teams/:teamId'

const HR_ADMIN_COMPARE_OVERALL_SCORE = HR_ADMIN_PAGE + 'compare-overall-score/'

const HR_ADMIN_COMPANY_EMPLOYEES = HR_ADMIN_PAGE + 'company-employees/'
const HR_ADMIN_INVITE_APPLICANTS = HR_ADMIN_PAGE + 'invite-applicants/'
const HR_ADMIN_COMPANY_EMPLOYEES_OLD = HR_ADMIN_PAGE + 'company-employees-old/'
const HR_ADMIN_COMPANY_EMPLOYEE_REPORT = HR_ADMIN_COMPANY_EMPLOYEES + 'report/'
const HR_ADMIN_COMPANY_EMPLOYEE_REPORT_PAGE = HR_ADMIN_COMPANY_EMPLOYEE_REPORT + ':employeeId'
const HR_ADMIN_COMPANY_EMPLOYEE_SUMMARY = HR_ADMIN_COMPANY_EMPLOYEES + 'summary/'
const HR_ADMIN_COMPANY_EMPLOYEE_SUMMARY_PAGE = HR_ADMIN_COMPANY_EMPLOYEE_SUMMARY + ':employeeId'

const MY_TODO_LIST = '/my-todo-list/'
const MY_SCHEDULES = '/my-schedules'
const MY_ASSESSMENTS = '/my-assessments/'
const MY_ASSESSMENTS_TAKING = MY_ASSESSMENTS + 'taking/'
const MY_ASSESSMENTS_TAKING_IN_PROGRESS = MY_ASSESSMENTS_TAKING + ':eeAssessmentId/:employeeId?'

const MY_ASSESSMENT_RESULTS = '/my-assessment-results/'
const MY_ASSESSMENT_RESULTS_DETAIL = MY_ASSESSMENT_RESULTS + ':id'

const MY_REPORTS = '/my-reports'

const SUPER_ADMIN = '/super-admin/'

const INTERNAL_REPORTS = SUPER_ADMIN + 'internal-reports/'
const INTERNAL_REPORTS_ASSIGN = (reportType = ':reportType') => INTERNAL_REPORTS + reportType

const CUSTOM_REPORTS = SUPER_ADMIN + 'custom-reports/'
const CUSTOM_REPORTS_ASSIGN = (customReportId = ':customReportId') =>
  CUSTOM_REPORTS + customReportId

const SUPER_ADMIN_EMPLOYEE_ASSESSMENT_RESULTS = '/employee-assessment-results/'
const SUPER_ADMIN_EMPLOYEE_ASSESSMENT_RESULTS_OVERVIEW =
  SUPER_ADMIN_EMPLOYEE_ASSESSMENT_RESULTS + ':id'

const ASSESSMENTS = '/assessments/'
const ASSESSMENTS_GROUP = ASSESSMENTS + 'groups/'
const ASSESSMENTS_MANAGEMENT = ASSESSMENTS + 'management/'
const ASSESSMENTS_NEW = ASSESSMENTS + 'new/'
const ASSESSMENTS_DETAIL = ASSESSMENTS_MANAGEMENT + ':id/'
const ASSESSMENTS_DETAIL_FORM = ASSESSMENTS_DETAIL + 'forms/'

const ASSESSMENTS_FORMS = ASSESSMENTS + 'forms/'
const ASSESSMENTS_FORMS_DETAIL = ASSESSMENTS_FORMS + ':formId/'
const ASSESSMENTS_FORMS_EDIT = ASSESSMENTS_FORMS_DETAIL + 'edit'
const ASSESSMENTS_FORMS_ASSIGN = ASSESSMENTS_FORMS_DETAIL + 'assign/'

const REPORTS = '/reports/'
const REPORTS_CONTENTS = REPORTS + 'contents'
const REPORTS_BUILDER = REPORTS + 'builder'
const QUESTIONS = '/questions/'
const QUESTIONS_MANAGEMENT = QUESTIONS + 'management/'
const QUESTIONS_NEW = QUESTIONS + 'new/'
const QUESTIONS_DETAIL = QUESTIONS_MANAGEMENT + ':id'

const MODULES = '/modules/'
const MODULES_MANAGEMENT = MODULES + 'management/'
const MODULES_NEW = MODULES + 'new/'
const MODULES_DETAIL = MODULES_MANAGEMENT + ':id'

const APPLICATIONS = '/applications/'
const APPLICATIONS_MANAGEMENT = APPLICATIONS + 'management/'
const APPLICATIONS_NEW = APPLICATIONS + 'new/'
const APPLICATIONS_DETAIL = APPLICATIONS_MANAGEMENT + ':id/'
const APPLICATIONS_DETAIL_EDIT = APPLICATIONS_DETAIL + 'edit/'
const APPLICATIONS_DETAIL_WORKFLOW = APPLICATIONS_DETAIL + 'workflow/'

const TEST_PPA = '/test-ppa'

const FAQ_MANAGEMENT = '/faq-management/'
const BASE_DATA = '/base-data/'
const BASE_DATA_PARENT_SCALE = BASE_DATA + 'parent-scale/'
const BASE_DATA_PARENT_SCALE_DETAIL = BASE_DATA_PARENT_SCALE + ':id'

const CHAT = '/chat'
const CHAT_PRIVATE = CHAT + '/private'
const CHAT_GROUP = CHAT + '/group'
const CHAT_DRAWER = CHAT + '/drawer'

const MENU_TEST = '/menu-test'

const ERROR = '/error'
const ERROR_404 = '/error/404'
const ERROR_500 = '/error/500'
const ERROR_NO_PERMISSION = '/error/no-permission'

const AUDIT_LOGS = '/audit-logs'
const AUDIT_LOG_SETTINGS = AUDIT_LOGS + '/settings'

const FORTE_TAKING_ASSESSMENTS = '/forte-assessments'

const PUBLIC_REPORT = (
  reportType = ':reportType',
  employeeId = ':employeeId',
  assessmentId = ':assessmentId'
) => `/share-report/${reportType}/${employeeId}/${assessmentId}`

const publicRoutes = {
  HOME,

  CONTACT_US,
  FAQ,
  PRIVACY,
  ABOUT,
  TERMS,

  AUTH,
  AUTH_LOGIN,
  AUTH_REGISTER,
  AUTH_INVITE_APPLICANT,
  AUTH_EMAIL_CONFIRMATION,
  RESET_PASSWORD,
  AUTH_LOGOUT,
  AUTH_FORGOT_PASSWORD,
  AUTH_TERMS,
  PUBLIC_REPORT,
}

const privateRoutes = {
  POINT_LEADER_REPORTS,

  DASHBOARD,
  DASHBOARD_EMPLOYEE,
  DASHBOARD_TEAM_LEADER,
  DASHBOARD_HR_MANAGER,
  DASHBOARD_MANAGER,
  DASHBOARD_ADMIN,

  EMPLOYEE,
  EMPLOYEE_PROFILE,
  EMPLOYEE_PROFILE_PAGE,
  EMPLOYEE_GOAL_SETTING,
  EMPLOYEE_GOAL_SETTING_PAGE,

  BUILDER,

  REPORTS,
  REPORTS_CONTENTS,
  REPORTS_BUILDER,

  ASSIGN_ASSESSMENTS,
  ASSIGN_ASSESSMENTS_MANAGEMENT,
  ASSIGN_ASSESSMENTS_CLIENT,
  ASSIGN_ASSESSMENTS_CLIENT_MANAGEMENT,

  MY_TODO_LIST,
  MY_SCHEDULES,
  MY_ASSESSMENTS,
  MY_ASSESSMENTS_TAKING,
  MY_ASSESSMENTS_TAKING_IN_PROGRESS,

  MY_ASSESSMENT_RESULTS,
  MY_ASSESSMENT_RESULTS_DETAIL,

  MY_REPORTS,

  HR_ADMIN_PAGE,
  HR_ADMIN_CLIENT_SITES_JOBS,
  HR_ADMIN_JOB_CUTS,
  HR_ADMIN_JOB_CUTS_DETAIL,
  HR_ADMIN_COMPANY_EMPLOYEES,
  HR_ADMIN_INVITE_APPLICANTS,
  HR_ADMIN_COMPANY_EMPLOYEES_OLD,
  HR_ADMIN_COMPANY_EMPLOYEE_REPORT,
  HR_ADMIN_COMPANY_EMPLOYEE_REPORT_PAGE,
  HR_ADMIN_COMPANY_EMPLOYEE_SUMMARY,
  HR_ADMIN_COMPANY_EMPLOYEE_SUMMARY_PAGE,
  HR_ADMIN_COMPARE_CANDIDATES,
  HR_ADMIN_COMPARE_GROUP,
  HR_ADMIN_TEAMS_ASSIGN_TEAM_MEMBERS,

  HR_ADMIN_COMPARE_OVERALL_SCORE,

  HR_ADMIN_REPORTS_PREVIEW: MY_CLIENT_REPORTS_PREVIEW,

  HR_ADMIN_CUSTOM_REPORTS_PREVIEW: MY_CLIENT_CUSTOM_REPORTS_PREVIEW,
  HR_ADMIN_CUSTOM_REPORTS_ASSIGN: MY_CLIENT_CUSTOM_REPORTS_ASSIGN,

  TEAM_LEADER_PAGE,
  TEAM_LEADER_MEMBERS,
  TEAM_LEADER_MEMBER_REPORT,
  TEAM_LEADER_MEMBER_REPORT_PAGE,
  TEAM_LEADER_MEMBER_SUMMARY,
  TEAM_LEADER_MEMBER_SUMMARY_PAGE,

  SUPER_ADMIN_EMPLOYEE_ASSESSMENT_RESULTS,
  SUPER_ADMIN_EMPLOYEE_ASSESSMENT_RESULTS_OVERVIEW,

  JOBS,
  JOBS_COMPETENCY_CUTS,
  JOBS_COMPETENCY_CUTS_DETAIL,

  USERS,
  USERS_MANAGEMENT,
  USERS_NEW,
  USERS_DETAIL,
  USERS_DETAIL_OVERVIEW,
  USERS_DETAIL_SETTINGS,

  MY_CLIENT,
  MY_CLIENT_INFO,
  MY_CLIENT_FORTE_ASSESSMENT_MANAGEMENT,
  MY_CLIENT_ASSESSMENT_FORMS,
  MY_CLIENT_TEAM_MANAGEMENT,
  MY_CLIENT_ASSIGN_REPORTS,
  MY_CLIENT_ASSIGN_CUSTOM_REPORTS,
  MY_CLIENT_SITES_JOBS,
  MY_CLIENT_JOB_CUTS,
  MY_CLIENT_JOB_CUTS_DETAIL,
  MY_CLIENT_EMPLOYEES,
  MY_CLIENT_ASSESSMENT_ASSIGN,
  MY_CLIENT_ASSESSMENT_ASSIGN_DETAILS,
  EMPLOYEE_INFO,
  EMPLOYEE_INFO_DETAIL,
  EMPLOYEE_INFO_DETAIL_OVERVIEW,
  EMPLOYEE_INFO_DETAIL_SETTINGS,
  EMPLOYEE_INFO_DETAIL_NOTES,
  MY_CLIENT_TRENDS,
  MY_CLIENT_NOTIFICATIONS,
  MY_CLIENT_NEWS,
  MY_CLIENT_REPORTS,
  MY_CLIENT_REPORTS_EDIT,

  CLIENTS,
  CLIENTS_MANAGEMENT,
  CLIENTS_NEW,
  CLIENTS_DETAIL,
  CLIENTS_SITES_JOBS,
  CLIENTS_SITES_JOB_COMPETENCY_CUTS,
  CLIENTS_SITES_JOB_COMPETENCY_CUTS_DETAIL,

  ASSESSMENTS,
  ASSESSMENTS_GROUP,
  ASSESSMENTS_MANAGEMENT,
  ASSESSMENTS_NEW,
  ASSESSMENTS_DETAIL,
  ASSESSMENTS_DETAIL_FORM,

  ASSESSMENTS_FORMS,
  ASSESSMENTS_FORMS_DETAIL,
  ASSESSMENTS_FORMS_EDIT,
  ASSESSMENTS_FORMS_ASSIGN,

  QUESTIONS,
  QUESTIONS_MANAGEMENT,
  QUESTIONS_NEW,
  QUESTIONS_DETAIL,

  MODULES,
  MODULES_MANAGEMENT,
  MODULES_NEW,
  MODULES_DETAIL,

  APPLICATIONS,
  APPLICATIONS_MANAGEMENT,
  APPLICATIONS_NEW,
  APPLICATIONS_DETAIL,
  APPLICATIONS_DETAIL_EDIT,
  APPLICATIONS_DETAIL_WORKFLOW,

  TEST_PPA,

  FAQ_MANAGEMENT,

  BASE_DATA,
  BASE_DATA_PARENT_SCALE,
  BASE_DATA_PARENT_SCALE_DETAIL,

  PROFILE,
  PROFILE_OVERVIEW,
  PROFILE_PROJECTS,
  PROFILE_CAMPAIGNS,
  PROFILE_DOCUMENTS,
  PROFILE_CONNECTIONS,

  WIZARDS,
  WIDGETS,

  ACCOUNT,
  ACCOUNT_OVERVIEW,
  ACCOUNT_SETTINGS,
  ACCOUNT_NOTES,

  CHAT,
  CHAT_GROUP,
  CHAT_PRIVATE,
  CHAT_DRAWER,

  MENU_TEST,

  SUPER_ADMIN,

  INTERNAL_REPORTS,
  INTERNAL_REPORTS_ASSIGN,

  CUSTOM_REPORTS,
  CUSTOM_REPORTS_ASSIGN,

  AUDIT_LOGS,
  AUDIT_LOG_SETTINGS,

  FORTE_TAKING_ASSESSMENTS,
}

const errorRoutes = {
  ERROR,
  ERROR_404,
  ERROR_500,
  ERROR_NO_PERMISSION,
}

const routerItems = {
  ...privateRoutes,

  ...publicRoutes,

  ...errorRoutes,
}

export default routerItems
